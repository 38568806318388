<template>
  <div class="expand-table-datalist">
    <div>
      <div v-if="tableId !== 'feedback' && tableId !== 'customer_type'" style="background: #FFFFFF; padding: 20px 20px 5px 20px; margin-bottom: 10px" v-loading="fieldFormLoading">
        <el-form style="display: grid; row-gap: 10px" label-position="top" label-width="100px" size="small" :inline="true" :model="queryForm" class="demo-form-inline" :style="fields.length >= 4 ? {gridTemplateColumns: 'repeat(4, calc(100% / 4))'} : {gridTemplateColumns: 'repeat(' + (fields.length + 1) + ', calc(100% / ' + (fields.length + 1) + '))'}">
          <el-form-item label="创建日期">
            <el-date-picker style="width: 100%" :picker-options="pickerOptions" v-model="createDateRange" type="datetimerange" range-separator="至" unlink-panels value-format="yyyy-MM-dd HH:mm:ss"
                            start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="alias === 'attendance' ? '打卡员工' : alias === 'gps' ? '定位用户' : alias === 'feedback' ? '反馈用户' : (alias === 'missed_punches' || alias === 'ask_for_leave') ? '申请人' : '创建人'">
            <el-select style="width: 100%" v-model="creator" clearable>
              <el-option v-for="option in staffList" :key="option.id" :label="option.real_name" :value="option.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-show="field.fieldType !== 'location'" v-for="field in fields" :key="field.id" :label="field.fieldDisplay" v-if="field.fieldType !== 'image'">
            <el-input style="width: 100%" v-if="field.fieldType === 'text'" v-model="queryForm[field.fieldCode]" :placeholder="field.fieldRemark" clearable></el-input>
            <el-input style="width: 100%" v-if="field.fieldType === 'number'" type="number" v-model="queryForm[field.fieldCode]" :placeholder="field.fieldRemark" clearable></el-input>
            <el-input style="width: 100%" v-if="field.fieldType === 'location'" v-model="queryForm[field.fieldCode]" :placeholder="field.fieldRemark" clearable></el-input>
            <el-select style="width: 100%" v-if="field.fieldType === 'enum'"  v-model="queryForm[field.fieldCode]" clearable>
              <el-option v-for="option in field.links" :key="option.value" :label="option.label" :value="option.value"></el-option>
            </el-select>
            <el-select style="width: 100%"
              @focus="clickSelect(field)"
              v-if="field.fieldType === 'table'"
              v-model="queryForm[field.fieldCode]"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="selectLoading[field.link]">
              <el-option
                v-for="item in options[field.link]"
                :key="item.id"
                :label="item.dataValue[field.linkField]"
                :value="item.id">
              </el-option>
            </el-select>
            <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 100%" v-if="field.fieldType === 'date'"
              v-model="queryForm[field.fieldCode]"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="background: #FFFFFF; padding: 20px" v-loading="loading">
      <div style="text-align: right; margin-bottom: 20px">
        <el-button size="small" type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        <el-button :disabled="fields.length<=0" type="success" size="small" @click="addData">添加数据</el-button>
      </div>
      <el-table
        v-if="fields.length > 0"
        :data="dataList"
        border
        style="width: 100%">
        <el-table-column
          min-width="170"
          label="编号">
          <template #default="{ row }">
            <el-tag size="small" type="info">{{ row.id }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in fields"
          :min-width="item.fieldType === 'image' ? 90 : item.fieldDisplay === '提交内容' ? 120 : '300'"
          :align="item.fieldType === 'image' ? 'center' : item.fieldDisplay === '提交内容' ? 'center' : 'left'"
          :key="item.id"
          :label="item.fieldDisplay">
          <template #default="{ row }">
            <el-image
              v-if="item.fieldType === 'image'"
              fit="cover"
              style="width: 60px; height: 60px"
              :src="(row.dataValue[item.fieldCode] && row.dataValue[item.fieldCode][0]) ? row.dataValue[item.fieldCode][0] : 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'"
              :preview-src-list="row.dataValue[item.fieldCode] ? row.dataValue[item.fieldCode] : ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">
            </el-image>
            <el-button @click="showCommitContent(row.dataValue[item.fieldCode])" type="primary" v-else-if="item.fieldDisplay === '提交内容' && row.dataValue[item.fieldCode]" size="mini">查看内容</el-button>
            <div v-else-if="tableId === 'sys_params' && item.fieldCode === 'value'">
              <el-tag type="success">{{ row.dataValue[item.fieldCode] }}</el-tag>
              <el-button @click="modifyParameterValue(row)" type="text" size="small" style="margin-left: 10px">修改</el-button>
            </div>
            <el-tag v-else-if="item.fieldType === 'table' && alias !== 'missed_punches'" type="primary" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'visit' && item.fieldCode === 'status' && row.dataValue[item.fieldCode] === '未开始'" type="info" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'visit' && item.fieldCode === 'status' && row.dataValue[item.fieldCode] === '进行中'" type="primary" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'visit' && item.fieldCode === 'status' && row.dataValue[item.fieldCode] === '已完成'" type="success" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'visit' && item.fieldCode === 'status' && row.dataValue[item.fieldCode] === '未完成'" type="warning" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
<!--            <el-tag v-else-if="item.fieldType === 'enum'" type="success" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>-->
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'attendance' && item.fieldCode === 'status' && row.dataValue.statusId === 4" type="info" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'attendance' && item.fieldCode === 'status' && row.dataValue.statusId === 0" type="success" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'attendance' && item.fieldCode === 'status' && row.dataValue.statusId === 3" type="danger" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && alias === 'attendance' && item.fieldCode === 'status' && (row.dataValue.statusId === 1 || row.dataValue.statusId === 2)" type="warning" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <span v-else-if="alias === 'attendance' && item.fieldCode === 'appeal_content' && row.dataValue[item.fieldCode] && row.dataValue[item.fieldCode].startsWith('[已处理1]')">
              <el-tag type="success" size="small">审批通过</el-tag>
              <span style="margin-left: 5px">{{ row.dataValue[item.fieldCode].replaceAll('[已处理1]', '') }}</span>
            </span>
            <span v-else-if="alias === 'attendance' && item.fieldCode === 'appeal_content' && row.dataValue[item.fieldCode] && row.dataValue[item.fieldCode].startsWith('[已处理0]')">
              <el-tag type="danger" size="small">审批拒绝</el-tag>
              <span style="margin-left: 5px">{{ row.dataValue[item.fieldCode].replaceAll('[已处理0]', '') }}</span>
            </span>
            <span v-else-if="alias === 'notification' && item.fieldCode === 'is_read'">{{ row.dataValue[item.fieldCode] === '1' ? '是' : '否' }}</span>
            <el-tag v-else-if="item.fieldType === 'enum' && (alias === 'ask_for_leave' || alias === 'missed_punches') && item.fieldCode === 'status' && row.dataValue.statusId === 0" type="info" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && (alias === 'ask_for_leave' || alias === 'missed_punches') && item.fieldCode === 'status' && row.dataValue.statusId === 1" type="success" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <el-tag v-else-if="item.fieldType === 'enum' && (alias === 'ask_for_leave' || alias === 'missed_punches') && item.fieldCode === 'status' && row.dataValue.statusId === 2" type="danger" size="small">{{ row.dataValue[item.fieldCode] }}</el-tag>
            <span v-else>{{ row.dataValue[item.fieldCode] ? row.dataValue[item.fieldCode] : '无' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          :label="alias === 'attendance' ? '打卡员工' : alias === 'gps' ? '定位用户' : alias === 'feedback' ? '反馈用户' : (alias === 'missed_punches' || alias === 'ask_for_leave') ? '申请人' : '创建人'">
          <template #default="{ row }">
            <span>{{ row.creatorName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          min-width="200"
          label="创建日期">
        </el-table-column>
<!--        <el-table-column-->
<!--          v-if="alias === 'visit'"-->
<!--          align="center"-->
<!--          width="100"-->
<!--          label="子任务">-->
<!--          <template scope="{ row }">-->
<!--            <el-button size="mini" type="primary" @click="showVisitTask(row)">查看</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
          v-if="roleName === '管理员' && alias !== 'sys_params'"
          align="center"
          :min-width="alias === 'visit' ? '150' : (alias === 'missed_punches' || alias === 'ask_for_leave') ? '200' : '100'"
          fixed="right"
          label="操作">
          <template scope="{ row }">
            <el-button v-if="alias === 'visit'" size="mini" type="primary" @click="showVisitTask(row)" icon="el-icon-view" circle></el-button>
            <el-popconfirm
              v-if="(alias === 'missed_punches' || alias === 'ask_for_leave')"
              icon-color="#67C23A"
              style="margin-right: 10px"
              title="是否审批通过此条补卡申请？"
              confirm-button-type="success"
              @confirm="passApproval(row)"
            >
              <el-button :disabled="row.dataValue.status !== '未处理'" slot="reference" v-if="(alias === 'missed_punches' || alias === 'ask_for_leave')" size="mini" type="success" icon="el-icon-check" circle></el-button>
            </el-popconfirm>
            <el-popconfirm
              v-if="(alias === 'missed_punches' || alias === 'ask_for_leave')"
              icon-color="#F56C6C"
              style="margin-right: 10px"
              title="是否拒绝通过此条补卡申请？"
              confirm-button-type="danger"
              @confirm="showRejectDialog(row)"
            >
              <el-button :disabled="row.dataValue.status !== '未处理'" slot="reference" v-if="(alias === 'missed_punches' || alias === 'ask_for_leave')" size="mini" type="danger" icon="el-icon-close" circle></el-button>
            </el-popconfirm>
            <el-button size="mini" type="warning" @click="editData(row)" icon="el-icon-edit" circle></el-button>
            <el-popconfirm
              style="margin-left: 10px"
              title="您确定要删除该数据吗？如有关联引用请慎重删除"
              @confirm="deleteData(row)"
            >
              <el-button slot="reference" size="mini" type="danger" icon="el-icon-delete" circle></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div v-else>
        <div style="text-align: center; color: rgb(128,128,128); margin-bottom: 100px; margin-top: 100px">
          请先创建字段
        </div>
      </div>
      <div style="text-align: right; margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[1, 2, 3, 10, 30, 50, 100, 200, 300, 500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-drawer
      title="子任务"
      :visible.sync="visitTaskDialog"
      size="500px">
      <div style="padding-top: 20px">
        <div v-loading="visitTaskLoading">
          <el-timeline :reverse="true">
            <el-timeline-item
              v-for="(activity, index) in visitTaskList"
              :key="index"
              :color="activity.status === '已完成' ? '#67C23A' : '#e4e7ed'"
              :timestamp="activity.commitDate">
              <div style="font-size: 18px; margin-bottom: 10px">{{activity.visit_type}}</div>
              <el-card style="width: calc(100% - 20px)" v-if="activity.commitForm.length > 0">
                <div v-for="(item, index) in activity.commitForm" :key="index">
                  <div v-if="item.value_type === 'gps'" style="margin: 10px 0; height: 300px; width: 100%" :id="activity.id"></div>
                  <div v-else-if="item.value_type === 'text'" style="margin: 10px 0; color: #0d47a1; font-weight: bold; font-size: 18px; background-color: #FFFFFF">{{ item.value_data }}</div>
                  <el-image
                    v-for="imgsrc in item.value_data"
                    v-else-if="item.value_type === 'image'"
                    fit="cover"
                    style="width: 100%; height: 300px; margin: 10px 0"
                    :src="imgsrc"
                    :preview-src-list="item.value_data">
                  </el-image>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <!--        <el-table-->
          <!--          :data="visitTaskList"-->
          <!--          border-->
          <!--          style="width: 100%">-->
          <!--          <el-table-column-->
          <!--            prop="dataValue.visit_type"-->
          <!--            label="类型">-->
          <!--          </el-table-column>-->
          <!--          <el-table-column-->
          <!--            align="center"-->
          <!--            width="120"-->
          <!--            prop="dataValue.visit_type"-->
          <!--            label="表单内容">-->
          <!--            <template #default="{ row }">-->
          <!--              <el-button v-if="row.dataValue.value" @click="showCommitContent(JSON.stringify(row.dataValue.value))" type="primary" size="mini">查看内容</el-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column-->
          <!--            prop="dataValue.status"-->
          <!--            label="状态">-->
          <!--          </el-table-column>-->
          <!--        </el-table>-->

          <!--        <div style="padding-top: 20px">-->
          <!--          <el-button style="width: 100%" type="success" icon="el-icon-position" size="mini" @click="showGpsLine">查看轨迹</el-button>-->
          <!--        </div>-->
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="拜访轨迹"
      :visible.sync="gpsLineDialog"
      width="1000px">
      <div style="height: 600px" id="gps-line"></div>
    </el-dialog>
    <el-drawer
      title="表单提交内容"
      :visible.sync="commitContentDialog"
      direction="rtl">
      <div style="padding: 20px">
        <div v-for="(item, index) in commitContent" :key="index">
          <div v-if="item.value_type === 'gps'" style="margin-bottom: 20px; height: 300px; width: 100%" id="location-picker"></div>
          <div v-else-if="item.value_type === 'text'" style="margin-bottom: 20px; color: #0d47a1; font-weight: bold; font-size: 18px; background-color: #FFFFFF">{{ item.value_data }}</div>
          <el-image
            v-for="imgsrc in item.value_data"
            v-else-if="item.value_type === 'image'"
            fit="cover"
            style="width: 100%; height: 300px; margin-bottom: 20px"
            :src="imgsrc"
            :preview-src-list="item.value_data">
          </el-image>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      style="transition: all .3s"
      :fullscreen="fullscreen"
      title="参数修改"
      :visible.sync="modifyDialog"
      :width="modifyDialogWidth">
      <div style="text-align: center">
        <div style="position: absolute; top: 20px; right: 50px">
          <svg v-if="!fullscreen" style="cursor:pointer;" @click="fullscreen = !fullscreen" t="1724996971930" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3429" width="16" height="16"><path d="M409.376 553.376L224 738.752V608a32 32 0 0 0-64 0v192.544c0 3.488 0.48 6.848 1.024 10.176a31.456 31.456 0 0 0 8.352 27.904c2.336 2.336 5.152 3.808 7.904 5.248 11.648 12.48 28.096 20.384 46.464 20.384h192.512a32 32 0 1 0 0-64l-163.488 0.224 201.856-201.856a31.968 31.968 0 1 0-45.248-45.248M800.512 160H608a32 32 0 0 0 0 64l146.944-0.192-201.568 201.568a31.968 31.968 0 1 0 45.248 45.248l201.632-201.632v147.264a32 32 0 1 0 64 0V223.744A63.84 63.84 0 0 0 800.512 160" fill="#3472c7" p-id="3430"></path></svg>
          <svg v-else style="cursor:pointer;" @click="fullscreen = !fullscreen" t="1724997288329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3927" width="16" height="16"><path d="M448.544 496H256a32 32 0 0 0 0 64l146.976-0.192-233.6 233.568a32 32 0 0 0 45.248 45.248l233.664-233.632v147.264a32 32 0 1 0 64 0v-192.512a63.84 63.84 0 0 0-63.744-63.744M838.624 201.376a31.968 31.968 0 0 0-45.248 0L576 418.752V272a32 32 0 0 0-64 0v192.544c0 35.136 28.608 63.712 63.744 63.712h192.512a32 32 0 1 0 0-64l-147.488 0.224 217.856-217.856a31.968 31.968 0 0 0 0-45.248" fill="#3472c7" p-id="3928"></path></svg>
        </div>
        <div v-loading="locationPickerLoading" v-if="modifyCode === 'companyLatLong'" id="locationPicker" style="width: 100%" :style="{ height: fullscreen ? 'calc(100vh - 180px)' : '500px' }"></div>
        <el-time-select
          style="width: 100%"
          v-else-if="modifyCode === 'offWorkTime' || modifyCode === 'workingTime'"
          v-model="modifyRowNewValue"
          :picker-options="{
            start: '00:00',
            step: '00:05',
            end: '23:55'
          }"
          placeholder="选择时间">
        </el-time-select>
        <el-select style="width: 100%" v-else-if="modifyCode === 'getLocationInterval'" v-model="modifyRowNewValue" placeholder="请选择">
          <el-option label="2分钟" value="2"></el-option>
          <el-option label="3分钟" value="3"></el-option>
          <el-option label="5分钟" value="5"></el-option>
        </el-select>
        <el-input-number style="width: 100%" v-else v-model="modifyRowNewValue"></el-input-number>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="normal" size="small" @click="modifyDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="confirmModifyParam">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="审批"
      :visible.sync="rejectDialog"
      width="500px">
      <div v-loading="rejectLoading">
        <el-input size="small" rows="5" type="textarea" v-model="rejectRemark" placeholder="请输入审批拒绝的说明"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="rejectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rejectApproval">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchData,
  fetchFormatData,
  getWhereabouts,
  updateSingleValue,
  deleteData,
  missedPunches,
  askForLeave
} from '@/api/expandTableValue'
import { getFields } from '@/api/expandTableFields'

export default {
  name: 'name',
  data () {
    return ({
      roleName: JSON.parse(window.localStorage.getItem('customType')).roleName,
      visitTaskDialog: false,
      visitTaskLoading: true,
      visitTaskList: [],
      visitId: '',
      options: {},
      selectLoading: {},
      queryForm: {},
      loading: false,
      tableId: '',
      fields: [],
      currentField: {},
      dataList: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      createDateRange: [],
      alias: '',
      fieldFormLoading: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      commitContent: [],
      commitContentDialog: false,
      map: null,
      gpsLineDialog: false,
      modifyRow: {
        dataValue: {
          value: ''
        }
      },
      modifyRowNewValue: '',
      modifyCode: '',
      modifyDialog: false,
      modifyLoading: false,
      modifyDialogWidth: '300px',
      locationPickerLoading: false,
      marker: null,
      fullscreen: false,
      row: {},
      rejectDialog: false,
      rejectRemark: '',
      rejectLoading: false,
      staffList: [],
      creator: ''
    })
  },
  beforeDestroy() {
    window.removeEventListener('storage')
  },
  async mounted () {
    this.tableId = this.$route.params.id
    this.alias = this.$route.params.id
    // if (this.tableId === '11e12c3c94cd4b29bb39117aeb749f34') {
    //   this.createDateRange = []
    // } else {
    //   this.createDateRange = this.getTodayTimeRange()
    // }
    await this.getFields()
    await this.fetchDataList(true)
    await this.fetchStaff()
    window.addEventListener('storage', async (e) => {
      if (e.key === 'WQtagState2') {
        await this.fetchDataList(false)
        localStorage.setItem('WQtagState2', JSON.stringify(false)) //变成false , 可以来回监听
      }
    })
  },
  methods: {
    async fetchStaff () {
      const res = await fetchFormatData({ alias: 'staff' })
      this.staffList = res.data.list
    },
    async passApproval (row) {
      this.loading = true
      if (this.alias === 'missed_punches') {
        const res = await missedPunches({ status: 1, data: row })
        if (res.code === 0) {
          this.$message.success(res.msg)
          await this.fetchDataList(false)
        } else {
          this.loading = fales
          this.$message.error(res.msg)
        }
      }
      if (this.alias === 'ask_for_leave') {
        const res = await askForLeave({ status: 1, data: row })
        if (res.code === 0) {
          this.$message.success(res.msg)
          await this.fetchDataList(false)
        } else {
          this.loading = fales
          this.$message.error(res.msg)
        }
      }
    },
    async showRejectDialog (row) {
      this.rejectRemark = ''
      this.row = row
      this.rejectDialog = true
    },
    async rejectApproval () {
      if (!this.rejectRemark) {
        this.$message.error('审批拒绝说明不可为空')
        return
      }
      this.rejectLoading = true
      if (this.alias === 'missed_punches') {
        const res = await missedPunches({ status: 0, data: this.row, remark: this.rejectRemark })
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.rejectLoading = false
          this.rejectDialog = false
          await this.fetchDataList(false)
        } else {
          this.loading = fales
          this.$message.error(res.msg)
        }
      }
      if (this.alias === 'ask_for_leave') {
        const res = await askForLeave({ status: 0, data: this.row, remark: this.rejectRemark })
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.rejectLoading = false
          this.rejectDialog = false
          await this.fetchDataList(false)
        } else {
          this.loading = fales
          this.$message.error(res.msg)
        }
      }
    },
    async deleteData (row) {
      this.loading = true
      const res = await deleteData(row)
      if (res.code === 0) {
        this.$message.success(res.msg)
        await this.fetchDataList(false)
      } else {
        this.loading = false
        this.$message.error(res.msg)
      }
    },
    async modifyParameterValue (row) {
      this.fullscreen = false
      this.modifyRow = row
      this.modifyCode = row.dataValue.code
      this.modifyRowNewValue = row.dataValue.value
      if (row.dataValue.code === 'companyLatLong') {
        this.modifyDialogWidth = '1000px'
        setTimeout(() => {
          const map = new AMap.Map("locationPicker", {
            viewMode: '2D', // 默认使用 2D 模式
            zoom: 13, // 地图级别
            center: [row.dataValue.value.split(',')[0], row.dataValue.value.split(',')[1]], // 地图中心点
          });
          if (this.marker) {
            map.remove(this.marker);
          }
          this.marker = new AMap.Marker({
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            position: [row.dataValue.value.split(',')[0], row.dataValue.value.split(',')[1]],
            anchor: 'bottom-center',
            content: '<img style="height: 32px" src="https://vae.life/res/icon/map/map-marker-blue.png">'
          });
          map.add(this.marker);
          map.setFitView()
          map.on("click", (ev) => {
            // 使用箭头函数，确保`this`指向Vue实例
            var lnglat = ev.lnglat;
            if (this.marker) {
              map.remove(this.marker);
            }
            this.marker = new AMap.Marker({
              icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
              position: [lnglat.lng, lnglat.lat],
              anchor: 'bottom-center',
              content: '<img style="height: 32px" src="https://vae.life/res/icon/map/map-marker-blue.png">'
            });
            map.add(this.marker);
            this.modifyRowNewValue = lnglat.lng + ',' + lnglat.lat
          });
        }, 200);
      } else {
        this.modifyDialogWidth = '300px'
      }
      this.modifyDialog = true
    },
    async confirmModifyParam () {
      const res = await updateSingleValue({
        id: this.modifyRow.id,
        code: 'value',
        value: this.modifyRowNewValue
      })
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.modifyDialog = false
        await this.fetchDataList(false)
      } else {
        this.$message.error(res.msg)
      }
    },
    async showGpsLine () {
      if (this.map) {
        this.map.destroy()
        this.map = null
      }
      this.gpsLineDialog = true
      const res = await getWhereabouts({ id: '1816675277430849537', date: '2024-08-06' })
      if (res.code === 0) {
        var polylines = []
        var center = null
        for (var i = 0; i < res.data.length; i++) {
          var path = []
          for (var j = 0; j < res.data[i].latLongArray.length; j++) {
            if (!center) {
              center = [res.data[i].latLongArray[j].split(',')[0], res.data[i].latLongArray[j].split(',')[1]]
            }
            path.push([res.data[i].latLongArray[j].split(',')[0], res.data[i].latLongArray[j].split(',')[1]])
          }
          polylines.push(new AMap.Polyline({
            path: path,
            isOutline: true,
            outlineColor: '#DCE0E6',
            borderWeight: 3,
            strokeColor: res.data[i].isOffline === 0 ? "#3472c7" : "#F56C6C",
            strokeOpacity: 1,
            strokeWeight: 6,
            // 折线样式还支持 'dashed'
            strokeStyle: "solid",
            // strokeStyle是dashed时有效
            strokeDasharray: [10, 5],
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 50,
          }))
        }
        setTimeout(() => {
          const map = new AMap.Map("gps-line", {
            viewMode: '2D', // 默认使用 2D 模式
            zoom: 10, // 地图级别
            center: center, // 地图中心点
          });
          this.map = map
          setTimeout(() => {
            map.add(polylines);
            map.setFitView();
          }, 100)
        }, 100);
      }
    },
    async showCommitContent (content) {
      this.commitContent = JSON.parse(content)
      console.log(this.commitContent)
      this.commitContentDialog = true
      if (this.map) {
        this.map.destroy()
        this.map = null
      }
      for (var i = 0; i < this.commitContent.length; i++) {
        if (this.commitContent[i].value_type === 'gps') {
          setTimeout(() => {
            const map = new AMap.Map("location-picker", {
              viewMode: '2D', // 默认使用 2D 模式
              zoom: 16, // 地图级别
              center: [this.commitContent[i].value_data.split(',')[0], this.commitContent[i].value_data.split(',')[1]], // 地图中心点
            });
            this.map = map
            if (this.marker) {
              map.remove(this.marker);
            }
            this.marker = new AMap.Marker({
              icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
              position: [this.commitContent[i].value_data.split(',')[0], this.commitContent[i].value_data.split(',')[1]],
              anchor: 'bottom-center',
            });
            map.add(this.marker);
          }, 100);
          break
        }
      }
    },
    async showVisitTask (row) {
      this.visitTaskList = []
      this.visitId = row.id
      this.visitTaskDialog = true
      await this.showVisitTaskList()
      for (var j = 0; j < this.visitTaskList.length; j++) {
        let commitContent = this.visitTaskList[j].commitForm
        for (var i = 0; i < commitContent.length; i++) {
          if (commitContent[i].value_type === 'gps') {
            let containerId = this.visitTaskList[j].id
            setTimeout(() => {
              const map = new AMap.Map(containerId, {
                viewMode: '2D', // 默认使用 2D 模式
                zoom: 16, // 地图级别
                center: [commitContent[i].value_data.split(',')[0], commitContent[i].value_data.split(',')[1]], // 地图中心点
              });
              let marker = new AMap.Marker({
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
                position: [commitContent[i].value_data.split(',')[0], commitContent[i].value_data.split(',')[1]],
                anchor: 'bottom-center',
              });
              map.add(marker);
            }, 100);
            break
          }
        }
      }
    },
    editData (row) {
      let routeUrl = this.$router.resolve({
        path: '/expandTableValue/data/edit/' + this.alias + '/' + row.id + '/',
      });
      window.open(routeUrl.href, '_blank');
    },
    async showVisitTaskList () {
      this.visitTaskLoading = true
      const res = await fetchFormatData({ alias: 'visit_task', queryForm: { "parent_id": this.visitId }, pageSize: 50, currentPage: 1 })
      this.visitTaskLoading = false
      if (res.code === 0 && res.data.list && res.data.list.length > 0) {
        for (var i = 0; i < res.data.list.length; i++) {
          res.data.list[i].dataValue = res.data.list[i]
          if (res.data.list[i].dataValue.value) {
            res.data.list[i].dataValue.commitForm = res.data.list[i].dataValue.value
          } else {
            res.data.list[i].dataValue.commitForm = []
          }
        }
        this.visitTaskList = res.data.list
        // this.total = res.data.total
      } else {
        this.visitTaskList = []
        // this.total = 0
      }
    },
    clickSelect (field) {
      this.currentField = field
      this.remoteMethod('')
    },
    async remoteMethod (keyword) {
      setTimeout(async () => {
        var qf = {}
        qf[this.currentField.linkField] = keyword
        this.selectLoading[this.currentField.link] = true
        this.$forceUpdate()
        const res = await fetchData({alias: this.currentField.link, queryForm: qf, pageSize: 50, currentPage: 1})
        if (res.code === 0 && res.data.list && res.data.list.length > 0) {
          for (var i = 0; i < res.data.list.length; i++) {
            res.data.list[i].dataValue = JSON.parse(res.data.list[i].recordValue)
          }
        }
        this.options[this.currentField.link] = res.data.list
        this.selectLoading[this.currentField.link] = false
        this.$forceUpdate()
      }, 100)
    },
    async handleSizeChange (value) {
      this.pageSize = value
      this.currentPage = 1
      await this.fetchDataList(false)
    },
    async handleCurrentChange (value) {
      this.currentPage = value
      await this.fetchDataList(false)
    },
    async onSubmit () {
      await this.fetchDataList(false)
    },
    addData () {
      this.$router.push('/settings/project/expandTableForm/' + this.tableId)
    },
    async getFields () {
      this.fieldFormLoading = true
      const res = await getFields({ alias: this.tableId })
      this.fieldFormLoading = false
      for (var i = 0; i < res.data.length; i++) {
        var links = []
        if (res.data[i].link) {
          let linkSplit = res.data[i].link.split(',')
          for (var j = 0; j < linkSplit.length; j++) {
            var item = {
              label: linkSplit[j].split('->')[1],
              value: linkSplit[j].split('->')[0]
            }
            links.push(item)
          }
          res.data[i].links = links
        }
      }
      this.fields = res.data
    },
    async fetchDataList (clearFilter) {
      this.loading = true
      this.dataList = []
      if (clearFilter) {
        this.queryForm = {}
        this.creator = ''
        this.currentPage = 1
      }
      const res = await fetchData({ creator: this.creator, alias: this.tableId, queryForm: this.queryForm, pageSize: this.pageSize, currentPage: this.currentPage, startDate: (this.createDateRange && this.createDateRange.length === 2) ? this.createDateRange[0] : '', endDate: (this.createDateRange && this.createDateRange.length === 2) ? this.createDateRange[1] : '' })
      this.loading = false
      this.alias = res.data.alias
      if (res.code === 0 && res.data.list && res.data.list.length > 0) {
        for (var i = 0; i < res.data.list.length; i++) {
          res.data.list[i].dataValue = JSON.parse(res.data.list[i].recordValue)
        }
        this.dataList = res.data.list
        this.total = res.data.total
      } else {
        this.dataList = []
        this.total = 0
      }
    },
    getTodayTimeRange () {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')

      const formattedStartTime = `${year}-${month}-${day} 00:00:00`
      const formattedEndTime = `${year}-${month}-${day} 23:59:59`

      return [formattedStartTime, formattedEndTime]
    }
  },
  computed: {
    // 监听数据
    getnewValue () {
      return this.$route.path
    }
  },
  watch: {
    //  监听改变事件
    getnewValue: {
      handler (newValue, oldValue) {
        this.tableId = this.$route.params.id
        this.getFields()
        this.fetchDataList(true)
      }
    }
  }
}
</script>
