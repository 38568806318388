import request from '@/utils/request'

export function addData (data) {
  return request({
    url: '/expandTableValue/save',
    method: 'POST',
    data
  })
}
export function fetchData (data) {
  return request({
    url: '/expandTableValue/list',
    method: 'POST',
    data
  })
}
