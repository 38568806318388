import request from '@/utils/request'

export function getFields (params) {
  return request({
    url: '/expandTableFields/list',
    method: 'get',
    params
  })
}

export function addField (data) {
  return request({
    url: '/expandTableFields/add',
    method: 'post',
    data
  })
}

export function updateField (data) {
  return request({
    url: '/expandTableFields/update',
    method: 'post',
    data
  })
}

export function deleteField (id) {
  return request({
    url: `/expandTableFields/delete/${id}`,
    method: 'delete'
  })
}
